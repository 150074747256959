<template>
  <div v-if="user">
    <v-app-bar
      app
      color="grey.darken4"
      clipped-left
      clipped-right
      dark
      elevation="8"
    >
      <v-app-bar-nav-icon @click="show_main_menu = !show_main_menu">
        <v-badge
          :content="count_unread"
          :value="count_unread"
          dot
          left
          color="red"
        >
          <v-icon>menu</v-icon>
        </v-badge>
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-menu
        v-model="showSearchResults"
        :close-on-content-click="false"
        transition="slide-y-transition"
        offset-y
        min-width="100%"
        bottom
      >
        <template v-slot:activator="{ attrs }">
          <v-text-field
            @keydown.enter="selectSearch(search)"
            @click="showDropDown()"
            @click:append="selectSearch(search)"
            @click:clear="clearSearch()"
            v-model="search"
            :loading="searchLoading"
            :placeholder="$t('search_form.search') + '...'"
            filled
            color="red"
            background-color="white"
            light
            rounded
            dense
            hide-details
            append-icon="mdi-magnify"
            single-line
            v-bind="attrs"
            clearable
          ></v-text-field>
        </template>
        <v-card v-if="search_history.length > 0 && search_products.length === 0">
          <v-list-item-group
            active-class="gray--text"
          >
            <v-list-item
              dense
              v-for="(item, index) in search_history"
              :key="index"
              @click="selectSearch(item)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-card>
        <v-card v-if="autocomplete.length > 0 || search_products.length > 0">
          <v-list-item-group
            active-class="gray--text"
          >
            <v-list-item
              dense
              v-for="(item, index) in autocomplete"
              :key="index"
              @click="selectSearch(item)"
            >
              <v-list-item-icon class="mr-1">
                <v-icon>search</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="grey--text darken-1">
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list>
            <v-divider></v-divider>
            <v-subheader>{{ $t('search_form.products') }}</v-subheader>
            <v-list-item-group
              active-class="gray--text"
            >
              <v-list-item
                dense
                v-for="(item, index) in search_products"
                :key="index"
                @click="goToProduct(item)"
              >
                <v-list-item-avatar v-if="item.image_url" width="40" height="30" tile>
                  <v-img :src="item.image_url"></v-img>
                </v-list-item-avatar>
                <v-list-item-avatar v-else width="40" height="30" tile>
                  <v-img src="/no-img.png"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ _tl(item, 'name') }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Kод: {{ item.code }} | Артикул: {{ item.vendor_code }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="item.special_price">
                  <small class="grey--text"><s>{{ formatPrice(item.price) }} грн</s></small>
                  <span class="red--text">{{ formatPrice(item.special_price) }} грн</span>
                </v-list-item-action>
                <v-list-item-action v-else>{{ formatPrice(item.price) }} грн</v-list-item-action>
              </v-list-item>
            </v-list-item-group>
            <v-divider></v-divider>
            <v-subheader>{{ $t('search_form.categories') }}</v-subheader>
            <v-list-item-group
              active-class="gray--text"
            >
              <v-list-item
                dense
                v-for="(item, index) in search_categories"
                :key="index"
                @click="goToCategory(item)"
              >
                <v-list-item-icon class="mr-1">
                  <v-icon>folder</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ _tl(item, 'name') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="selectSearch(search)"
                dense
              >
                <v-list-item-content class="green--text">
                  <v-list-item-title>{{ $t('search_form.show_all') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
      <v-spacer></v-spacer>
      <v-btn
        @click="$router.push('/dashboard/cart')"
        class="ml-2"
        small
        depressed
        fab
      >
        <v-badge
          :content="cart.length"
          :value="cart.length"
          color="green"
          overlap
        >
          <v-icon>
            shopping_cart
          </v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar>
    <MainMenuAsideComponent :is_show="show_main_menu" @show-main-menu="setShowMenu"/>
    <v-main>
      <transition name="fade-transition" mode="out-in">
        <router-view/>
      </transition>
    </v-main>
  </div>
  <div v-else class="text-center" style="margin-top: 50vh;">
    <v-progress-circular
      :size="50"
      color="amber"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import MainMenuAsideComponent from '@/components/asides/MainMenuAsideComponent'
import { mapActions, mapMutations, mapState } from 'vuex'
import { translate } from '@/mixins/translate'
import { price } from '@/mixins/price'
import { ws } from '@/mixins/ws_notification'

export default {
  name: 'DashboardMobileLayout',
  mixins: [translate, price, ws],
  components: {
    MainMenuAsideComponent
  },
  data: () => ({
    show_main_menu: false,
    show_aside: false,
    searchTimer: null,
    searchLoading: false,
    showSearchResults: false
  }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('carts', ['cart']),
    ...mapState('search', [
      'search_request',
      'autocomplete',
      'search_products',
      'search_categories',
      'search_history'
    ]),
    search: {
      get () {
        return this.search_request
      },
      set (v) {
        if (!v || (v && v.length < 3)) {
          return
        }
        this.searchLoading = true
        clearTimeout(this.searchTimer)
        this.setSearchRequest(v)
        this.searchTimer = setTimeout(() => {
          this.searchLoading = false
          this.showSearchResults = true
          this.searchRequest()
        }, 200)
      }
    }
  },
  methods: {
    ...mapActions('auth', ['getUser', 'logout']),
    ...mapActions('carts', ['getCart']),
    ...mapActions('search', ['searchRequest']),
    ...mapMutations('search', [
      'setSearchRequest',
      'setAutocomplete',
      'setSearchProducts',
      'addSearchHistory'
    ]),
    ...mapMutations('products', ['setProductSearch', 'setCategoryId', 'setSorting']),
    ...mapActions('products', ['getProducts']),
    selectSearch (item) {
      this.showSearchResults = false
      this.setSearchRequest('')
      this.setProductSearch(item)
      this.setAutocomplete([])
      this.setSearchProducts([])
      this.setCategoryId(null)
      if (this.search_history.findIndex(i => i === item) === -1) {
        this.addSearchHistory(item)
      }
      this.setSorting(99)
      this.$router.push({
        path: '/dashboard/products',
        query: {
          search: item,
          page: 1
        }
      }).catch(e => {
      })
    },
    clearSearch () {
      this.showSearchResults = false
      this.setSearchRequest('')
      this.setProductSearch('')
      this.setAutocomplete([])
      this.setSearchProducts([])
    },
    goToProduct (item) {
      this.clearSearch()
      this.$router.push('/dashboard/products/' + item.id)
    },
    goToCategory (item) {
      this.clearSearch()
      this.$router.push('/dashboard/catalog/' + item.id + '/products')
    },
    showDropDown () {
      this.showSearchResults = true
    },
    setShowMenu (v) {
      this.show_main_menu = v
    }
  },
  created () {
    const promise = this.getUser()
    promise.then(response => {
      this.joinChannelNotifications()
    })
    this.setSearchRequest(this.$route.query.search)
    this.getCart()
  }
}
</script>

<style scoped>

</style>
