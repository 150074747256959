<template>
  <v-navigation-drawer
      v-model="show_aside"
      app
      dark
      clipped
      :src="this.$vuetify.breakpoint.mobile ? '/bg-img.png' : '/bg-img.png'"
      @input="setShow"
  >
    <v-list
        nav
        dense
    >
      <div v-if="this.$vuetify.breakpoint.mobile">
        <v-list-item>
          <v-list-item-content>
            <div class="d-flex align-center justify-center">
              <v-img
                  alt="Etalon Logo"
                  class="shrink mr-2 ml-2"
                  contain
                  src="/logo.png"
                  transition="scale-transition"
                  width="160"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </div>
      <v-subheader>{{ $t('users.user') }}</v-subheader>
      <v-menu
          offset-x
          transition="scale-transition" class="mx-4"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
              v-bind="attrs"
              v-on="on"
          >
            <v-icon class="mr-2" dark>
              mdi-account-circle
            </v-icon>
            <v-list-item-title>{{ user.name }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-list>
          <v-list-item
              to="/dashboard/profile"
              dense
          >
            <v-list-item-title>{{ $t('users.profile') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
              dense
              @click="logout()">
            <v-list-item-title>{{ $t('login.out') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-divider></v-divider>
      <v-subheader>Меню</v-subheader>
      <v-list-item-group
          active-class="gray--text"
      >
        <v-list-item to="/dashboard/catalog">
          <v-icon class="mr-2">list</v-icon>
          <v-list-item-title>Каталог</v-list-item-title>
        </v-list-item>
        <v-list-item
          style="background: rgb(228 24 58 / 75%)"
          tag="a"
          target="_blank"
          href="https://api-products.tm-etalon.com.ua/storage/Etalon_Specials.code.pdf"
        >
          <v-icon class="mr-2">folder_special</v-icon>
          <v-list-item-title>{{ $t('special_pdf') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/orders">
          <v-icon class="mr-2">wysiwyg</v-icon>
          <v-list-item-title>{{ $t('orders.label') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/news">
          <v-icon class="mr-2">fiber_new</v-icon>
          <v-list-item-title>{{ $t('catalog.new_products') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/specials">
          <v-icon class="mr-2">percent</v-icon>
          <v-list-item-title>{{ $t('catalog.special_products') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/favorites">
          <v-icon class="mr-2 gr">
            favorite
          </v-icon>
          <v-list-item-title>{{ $t('favorites.label') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="this.$vuetify.breakpoint.mobile" to="/dashboard/notifications">
          <v-icon class="mr-2">
            notifications
          </v-icon>
          <v-list-item-title>
            {{ $t('notifications.label') }}
            <v-badge
                class="ms-2"
                :content="count_unread"
                :value="count_unread"
                color="red"
            ></v-badge>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="this.$vuetify.breakpoint.mobile && user && !user.telegram_chat"
          @click="show_join_telegram = true"
        >
          <v-img class="mr-2" height="24px" width="24px" src="/images/telegram-app.svg"/>
          <v-list-item-title>
            {{ $t('telegram.join') }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
      <v-divider></v-divider>
      <v-list-item-group
          active-class="gray--text"
      >
        <v-list-item
            tag="a"
            target="_blank"
            href="https://drive.google.com/drive/folders/0ByRR6G3q3rDJaW9yelYwOV9KQjA?resourcekey=0-9hIE_a5psqp7KB5n2E4Q9w"
        >
          <v-icon class="mr-2">verified_user</v-icon>
          <v-list-item-title>{{$t('other.cert')}}</v-list-item-title>
        </v-list-item>
<!--        -->
        <v-list-item
            v-for="article in aside_articles"
            :to="'/dashboard/article/' + article.id"
            :key="article.id"
        >
          <v-icon class="mr-2" v-if="article.icon">{{ article.icon }}</v-icon>
          <v-list-item-title>{{ article.name }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <JoinTelegramBotModal v-model="show_join_telegram"/>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ws } from '@/mixins/ws_notification'
import JoinTelegramBotModal from '@/components/Telegram/Modals/JoinTelegramBotModal'

export default {
  name: 'MainMenuAsideComponent',
  components: { JoinTelegramBotModal },
  props: ['is_show'],
  mixins: [ws],
  data: () => ({
    show_aside: false,
    show_join_telegram: false
  }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('content', ['aside_articles'])
  },
  watch: {
    is_show: {
      immediate: true,
      handler (v) {
        this.show_aside = v
        this.$emit('show-main-menu', v)
      }
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('content', ['getAsideArticles']),
    setShow (v) {
      this.$emit('show-main-menu', v)
    }
  },
  created () {
    this.getAsideArticles()
  }
}
</script>

<style scoped>

</style>
