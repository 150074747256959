import Vue from 'vue'
import Vuex from 'vuex'
import catalog from '@/store/modules/catalog'
import products from '@/store/modules/products'
import auth from '@/store/modules/auth'
import errors from '@/store/modules/errors'
import lang from '@/store/modules/lang'
import orders from '@/store/modules/orders'
import carts from '@/store/modules/carts'
import search from '@/store/modules/search'
import users from '@/store/modules/users'
import prices from '@/store/modules/prices'
import alerts from '@/store/modules/alerts'
import novaPoshta from '@/store/modules/nova-poshta'
import content from '@/store/modules/content'
import analogues from '@/store/modules/analogues'
import notifications from '@/store/modules/notifications'
import filters from '@/store/modules/filters'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    catalog,
    products,
    errors,
    lang,
    orders,
    carts,
    search,
    users,
    prices,
    alerts,
    novaPoshta,
    content,
    analogues,
    notifications,
    filters
  }
})
