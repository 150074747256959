<template>
  <div v-if="user">
    <v-app-bar
      app
      color="grey.darken4"
      clipped-left
      clipped-right
      dark
      elevation="8"
    >
      <v-app-bar-nav-icon @click="show_aside = !show_aside"></v-app-bar-nav-icon>
      <div
        class="d-flex align-center cursor-pointer"
        @click="$router.push('/dashboard/catalog').catch(e => {})"
      >
        <v-img
          alt="Etalon Logo"
          class="shrink mr-2 ml-2"
          contain
          src="/logo.png"
          transition="scale-transition"
          width="180"
        />
      </div>
      <v-btn
        class="ml-6"
        color="#e00027"
        @click="$router.push('/dashboard/catalog')"
      >
        <v-icon class="mr-2">list</v-icon>
        Каталог
      </v-btn>
      <v-menu
        v-model="showSearchResults"
        :close-on-content-click="false"
        transition="slide-y-transition"
        bottom
        offset-y
        max-height="95vh"
      >
        <template v-slot:activator="{ attrs }">
          <v-text-field
            class="ml-16 mr-8 search-field"
            color="red"
            light
            background-color="white"
            @keydown.enter="selectSearch(search)"
            @click="showDropDown()"
            @click:append="selectSearch(search)"
            @click:clear="clearSearch()"
            v-model="search"
            :loading="searchLoading"
            :placeholder="$t('search_form.search') + '...'"
            hide-details
            solo
            dense
            append-icon="mdi-magnify"
            v-bind="attrs"
            clearable
          ></v-text-field>
        </template>
        <v-card v-if="search_history.length > 0 && search_products.length === 0">
          <v-list-item-group
            active-class="gray--text"
          >
            <v-list-item
              dense
              v-for="(item, index) in search_history"
              :key="index"
              @click="selectSearch(item)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-card>
        <v-card v-if="autocomplete.length > 0 || search_products.length > 0">
          <v-list-item-group
            active-class="gray--text"
          >
            <v-list-item
              dense
              v-for="(item, index) in autocomplete"
              :key="index"
              @click="selectSearch(item)"
            >
              <v-list-item-icon class="mr-1">
                <v-icon>search</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="grey--text darken-1">
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list
          >
            <v-divider></v-divider>
            <v-subheader>{{ $t('search_form.products') }}</v-subheader>
            <v-list-item-group
              active-class="gray--text"
            >
              <v-list-item
                dense
                v-for="(item, index) in search_products"
                :key="index"
                @click="goToProduct(item)"
              >
                <v-list-item-avatar v-if="item.image_url" width="40" height="30" tile>
                  <v-img :src="item.image_url"></v-img>
                </v-list-item-avatar>
                <v-list-item-avatar v-else width="40" height="30" tile>
                  <v-img src="/no-img.png"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ _tl(item, 'name') }}</v-list-item-title>
                  <v-list-item-subtitle>Kод: {{ item.code }} | Артикул: {{ item.vendor_code }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="text-center" v-if="item.special_price">
                  <small class="grey--text"><s>{{ formatPrice(item.price) }} грн</s></small>
                  <span class="red--text">{{ formatPrice(item.special_price) }} грн</span>
                </v-list-item-action>
                <v-list-item-action v-else>{{ formatPrice(item.price) }} грн</v-list-item-action>
              </v-list-item>
            </v-list-item-group>
            <v-divider></v-divider>
            <v-subheader>{{ $t('search_form.categories') }}</v-subheader>
            <v-list-item-group
              active-class="gray--text"
            >
              <v-list-item
                dense
                v-for="(item, index) in search_categories"
                :key="index"
                @click="goToCategory(item)"
              >
                <v-list-item-icon class="mr-1">
                  <v-icon>folder</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ _tl(item, 'name') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="selectSearch(search)"
                dense
              >
                <v-list-item-content class="green--text">
                  <v-list-item-title>{{ $t('search_form.show_all') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
      <v-spacer></v-spacer>
      <!--   TELEGRAM   -->
      <v-btn
        v-if="user && !user.telegram_chat"
        @click="showTelegramModel = true"
        depressed
        class="mx-2"
        fab
        dark
        small
      >
        <v-img height="30px" src="/images/telegram-app.svg"/>
      </v-btn>
      <!--   LANG   -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            text
          >
            <img height="24px" style="margin-right: 10px" src="/images/ua.svg" alt="UA"/>
            {{ lang.toUpperCase() }}
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group
            v-model="selectedLang"
            color="primary"
          >
            <v-list-item
              v-for="item in lang_list"
              :key="item"
            >
              <v-list-item-title>{{ item.toUpperCase() }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <!--  ORDERS    -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="$router.push('/dashboard/orders')"
            depressed
            class="mx-2"
            fab
            dark
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              format_list_bulleted
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('orders.label') }}</span>
      </v-tooltip>
      <!--  User    -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            v-bind="attrs"
            v-on="on"
            small
            fab
            depressed
          >
            <v-icon>person</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon
                class="grey lighten-1"
                dark
              >person
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title style="font-size: 16px">
                {{ user.name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ user.address }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item-group
            color="primary"
          >
            <v-list-item @click="$router.push('/dashboard/profile')">
              <v-list-item-title>
                {{ $t('settings') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-list-item-title>{{ $t('login.out') }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <!--  Favorites   -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="$router.push('/dashboard/favorites')"
            depressed
            class="mx-2"
            fab
            dark
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="gr">
              favorite
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('favorites.label') }}</span>
      </v-tooltip>
      <v-btn
        @click="toNotifications()"
        depressed
        class="mx-2"
        fab
        dark
        small
      >
        <v-badge
          :content="count_unread"
          :value="count_unread"
          color="red"
          overlap
        >
          <v-icon>
            notifications
          </v-icon>
        </v-badge>
      </v-btn>
      <v-btn
        @click="$router.push('/dashboard/cart')"
        depressed
        class="mx-2"
        fab
        dark
        small
      >
        <v-badge
          :content="cart.length"
          :value="cart.length"
          color="green"
          overlap
        >
          <v-icon>
            shopping_cart
          </v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar>
    <MainMenuAsideComponent :is_show="show_aside"/>
    <SecondAsidePanel/>
    <v-main>
      <transition name="fade-transition" mode="out-in">
        <router-view/>
      </transition>
    </v-main>
    <JoinTelegramBotModal
      v-model="showTelegramModel"
    />
  </div>
  <div v-else class="text-center" style="margin-top: 50vh;">
    <v-progress-circular
      :size="50"
      color="amber"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import MainMenuAsideComponent from '@/components/asides/MainMenuAsideComponent'
import SecondAsidePanel from '@/components/asides/SecondAsidePanel'
import { mapActions, mapMutations, mapState } from 'vuex'
import { translate } from '@/mixins/translate'
import { price } from '@/mixins/price'
import { ws } from '@/mixins/ws_notification'
import JoinTelegramBotModal from '@/components/Telegram/Modals/JoinTelegramBotModal'

export default {
  name: 'DashboardLayout',
  mixins: [translate, price, ws],
  components: {
    JoinTelegramBotModal,
    MainMenuAsideComponent,
    SecondAsidePanel
  },
  data: () => ({
    menu: true,
    searchTimer: null,
    searchLoading: false,
    show_aside: true,
    showSearchResults: false
  }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('lang', ['lang', 'lang_list']),
    ...mapState('carts', ['cart']),
    ...mapState('search', [
      'search_request',
      'autocomplete',
      'search_products',
      'search_categories',
      'search_history'
    ]),
    selectedLang: {
      get () {
        return this.lang
      },
      set (v) {
        this.setLang(this.lang_list[v])
        const lang = v === 0 ? 'uk' : 'ru'
        this.changeUserLang(lang)
      }
    },
    search: {
      get () {
        return this.search_request
      },
      set (v) {
        if (!v || (v && v.length < 3)) {
          return
        }

        this.searchLoading = true
        clearTimeout(this.searchTimer)
        this.setSearchRequest(v)
        this.searchTimer = setTimeout(() => {
          this.searchLoading = false
          this.showSearchResults = true
          this.searchRequest()
        }, 300)
      }
    }
  },
  methods: {
    ...mapActions('auth', ['getUser', 'logout']),
    ...mapActions('carts', ['getCart']),
    ...mapMutations('lang', ['setLang']),
    ...mapActions('lang', ['changeUserLang']),
    ...mapActions('system', ['getLogs']),
    ...mapActions('search', ['searchRequest']),
    ...mapMutations('search', [
      'setSearchRequest',
      'setAutocomplete',
      'setSearchProducts',
      'addSearchHistory'
    ]),
    ...mapMutations('products', ['setProductSearch', 'setSorting']),
    ...mapActions('products', ['getProducts']),
    selectSearch (item) {
      this.showSearchResults = false
      this.setSearchRequest(item)
      this.setProductSearch(item)
      this.setSorting(99)
      if (this.search_history.findIndex(i => i === item) === -1) {
        this.addSearchHistory(item)
      }
      this.$router.push({
        path: '/dashboard/products',
        query: { search: item }
      })
    },
    clearSearch () {
      this.showSearchResults = false
      this.setSearchRequest('')
      this.setProductSearch('')
      this.setAutocomplete([])
      this.setSearchProducts([])
    },
    goToProduct (item) {
      this.clearSearch()
      this.$router.push('/dashboard/products/' + item.id)
    },
    goToCategory (item) {
      this.clearSearch()
      this.$router.push('/dashboard/catalog/' + item.id + '/products')
    },
    showDropDown () {
      this.showSearchResults = true
    },
    toNotifications () {
      if (this.$route.path === '/dashboard/notifications') {
        this.setNotificationPage(1)
        this.getNotifications()
      } else {
        this.$router.push('/dashboard/notifications')
      }
    }
  },
  created () {
    const promise = this.getUser()
    promise.then(response => {
      this.joinChannelNotifications()
    })
    this.getCart()
  }
}
</script>

<style lang="scss">
.search-field {
  width: 100%;

  input {
    cursor: text !important;
  }
}

.gr {
  background: linear-gradient(to bottom, #0057b8 50%, #ffd700 50%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  /*c: linear-gradient(to bottom, #005BBB 50%, #FFD500 50%);*/
  /*-webkit-background-clip: text;*/
  /*-webkit-text-fill-color: transparent;*/
}
</style>
