import axios from 'axios'

const baseURL = process.env.VUE_APP_PRODUCT_SERVICE + '/api/carts'

export default {
  namespaced: true,
  state: {
    is_load_cart: false,
    cart: [],
    selected: []
  },
  // getters
  getters: {
    cart_ids (state) {
      const ids = []
      state.cart.forEach(item => {
        ids.push(item.id)
      })
      return ids
    },
    cart_total (state) {
      return state.selected.reduce((total, product) => {
        return total + ((product.special_price || product.price) * product.qty)
      }, 0)
    },
    getSelectedCart (state, getters, rootState) {
      const items = []
      const index = rootState.prices.selected_type
      const priceType = rootState.prices.price_type_list[index]
      const percent = priceType ? priceType.percent : 0
      console.log('root', percent)

      state.selected.forEach(item => {
        const index = state.cart.findIndex(cart => cart.id === item.id)
        if (index > -1) {
          const selectedItem = state.cart[index]
          selectedItem.price = selectedItem.price * (percent / 100) + selectedItem.price
          selectedItem.special_price = selectedItem.special_price * (percent / 100) + selectedItem.special_price
          items.push(selectedItem)
        }
      })
      return items
    }
  },
  mutations: {
    setLoadCart (state, value) {
      state.is_load_cart = value === true
    },
    setSelectedCart (state, v) {
      state.selected = v
    },
    setCart (state, payload) {
      state.cart = payload
    },
    setCartItem (state, payload) {
      const selectIndex = state.selected.findIndex(item => item.id === payload.id)

      if (selectIndex !== -1) {
        state.selected[selectIndex] = payload
        state.selected = [...state.selected]
      }

      const index = state.cart.findIndex(item => item.id === payload.id)

      if (index !== -1) {
        state.cart[index] = payload
      } else {
        // push to 0
        state.cart.splice(0, 0, payload)
      }

      state.cart = [...state.cart]
    },
    deleteSelectedItem (state, cartItem) {
      const selectIndex = state.selected.findIndex(item => item.id === cartItem.id)
      if (selectIndex !== -1) {
        state.selected.splice(selectIndex, 1)
        state.selected = [...state.selected]
      }
    }
  },
  actions: {
    async getCart ({
      commit
    }) {
      commit('setLoadCart', true)
      const promise = axios.get(baseURL, {})
      promise.then(response => {
        commit('setCart', response.data.data)
        commit('setLoadCart', false)
      }).catch(e => {
        commit('setLoadCart', false)
      })
      return promise
    },
    async addToCart ({
      state,
      commit
    }, data) {
      commit('setLoadCart', true)
      const promise = axios.post(baseURL, data)
      promise.then(response => {
        commit('setLoadCart', false)
        commit('setCart', response.data.data)
      }).catch(e => {
        commit('setLoadCart', false)
      })
      return promise
    },
    async updateCart ({
      state,
      commit
    }, data) {
      commit('setLoadCart', true)
      const promise = axios.put(baseURL + '/' + data.id, data)
      promise.then(response => {
        commit('setLoadCart', false)
        commit('setCartItem', response.data.data)
      }).catch(e => {
        commit('setLoadCart', false)
      })
      return promise
    },
    async deleteFromCart ({
      state,
      commit
    }, item) {
      commit('setLoadCart', true)
      const promise = axios.delete(baseURL + '/' + item.id)
      promise.then(response => {
        commit('setLoadCart', false)
        commit('setCart', response.data.data)
        commit('deleteSelectedItem', item)
      }).catch(e => {
        commit('setLoadCart', false)
      })
      return promise
    },
    async checkCartRemains ({
      state,
      commit
    }) {
      const ids = []
      state.selected.forEach(item => {
        ids.push(item.id)
      })
      commit('setLoadCart', true)
      const promise = axios.post(baseURL + '/check-remains', {
        ids: ids
      })
      promise.then(response => {
        commit('setLoadCart', false)
        commit('setCart', response.data.data)
      }).catch(e => {
        commit('setLoadCart', false)
      })
      return promise
    },
    async clearCart ({
      commit
    }) {
      commit('setLoadCart', true)
      const promise = axios.delete(baseURL)
      promise.then(response => {
        commit('setLoadCart', false)
        commit('setCart', response.data.data)
      }).catch(e => {
        commit('setLoadCart', false)
      })
      return promise
    },
    async clearCartSelected ({
      state,
      commit
    }) {
      const ids = []
      state.selected.forEach(item => {
        ids.push(item.id)
      })
      commit('setLoadCart', true)
      const promise = axios.delete(baseURL + '/selected', {
        params: {
          ids: ids
        }
      })
      promise.then(response => {
        commit('setLoadCart', false)
        commit('setCart', response.data.data)
        commit('setSelectedCart', [])
      }).catch(e => {
        commit('setLoadCart', false)
      })
      return promise
    }
  }
}
